@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@600&display=swap');

body {
  font-family: 'Montserrat', sans-serif;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  font-weight: 800;
  margin: 0;
  background-color: #000000;
}

.flex-div {
  max-width: 600px;
  margin: 200px auto 0;
  display: flex;
}

.vertical-line {
  width: 30px;
  height: 3400px;
  margin-right: 20px;
  background-color: #D25B5B;
  border-radius: 20px 20px 0 0;
  flex-shrink: 0;
}

.title {
  font-size: 125px;
  color: #fff;
  margin: 0;
  text-align: left;
  text-shadow: 6px 4px 0px #6A5ACD;
  line-height: 118%;
}

.title-line {
  width: 200px;
  height: 16px;
  margin-left: 30px;
  background-color: #D25B5B;
  border-radius: 20px;
  position: absolute;
  top: 342px;
}

.line-mini {
  width: 50px;
  margin-left: 250px;
}

.alt-flex {
  display: flex;
  justify-content: center;
}

.line-text {
  color: #fff;
  font-size: 40px;
  height: fit-content;
  margin: 0 0 0 -64px;
  transform: rotate(270deg);
  position: fixed;
  top: 270px;
  transition: .2s;
}

.sub-title {
  color: #FFFFFF;
  font-size: 40px;
  margin: 500px 0 0 0;
}

.sub-title b {
  color: #6A5ACD;
  font-size: 52px;
  font-family: 'Kanit', sans-serif;
}

.boxes {
  width: 98%;
}

.boxes:nth-child(3) {
  width: 110%;
}

.box {
  width: 84px;
  height: 84px;
  margin: 10px;
  padding: 16px;
  background-color: #555555;
  border-radius: 20px;
  display: inline-flex;
  transition: .2s;
}

.boxes .box:nth-child(5) {
  margin-left: 60px;
}

.box:nth-child(14) {
  margin-left: 120px;
}

.box:hover {
  transform: scale(1.1);
}

.box img {
  max-width: 100%;
  object-fit: contain;
}

.social {
  cursor: pointer;
}

.footer {
  color: #555;
  margin-top: 300px;
}

.footer h3 {
  color: #aaa;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .vertical-line {
    height: 3700px;
  }

  .line-text {
    display: none;
  }

  .boxes .box:nth-child(5) {
    margin-left: 10px;
  }
  
  .box:nth-child(14) {
    margin-left: 10px;
  }

  .footer {
    margin-top: 50%;
  }
}
/* Genel Anket Yapısı */
.survey-container {
  min-height: 300px;
  color: #e0e0e0;
  margin: 20px 0 30px;
  padding: 0;
  text-align: center;
  background: linear-gradient(345deg, #009c1c, #000000);
  border-radius: 12px;
}

.survey-title {
  font-size: 36px;
  color: #f4f4f4;
  margin-bottom: 25px;
}

/* Anket */
.survey-poll {
  position: relative;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  transition: transform .3s;
  color: #fff;
}

.survey-question {
  font-size: 24px;
  color: #f4f4f4;
  margin: 8px 0 15px;
}

/* Seçenekler */
.survey-options {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.survey-optionButton {
  position: relative;
  width: 100%;
  padding: 12px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.3s;
  text-align: left;
  overflow: hidden;
  background: #D25B5B;
}

.survey-optionButton:hover {
  filter: brightness(0.9);
}

.survey-optionButton:disabled, 
.survey-optionButton.disabled {
  background-color: gray;
  cursor: not-allowed;
}

.survey-optionButton::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--vote-percentage, 0%);
  background: linear-gradient(90deg, #D25B5B, #D25B5B);
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
}

.survey-optionText {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selected-option {
  border: 3px solid white !important;
}

/* Anket Sonuçları */
.survey-results {
  margin-top: 25px;
}

.survey-resultsTitle {
  font-size: 22px;
  color: #f4f4f4;
  margin-bottom: 20px;
}

.survey-resultList {
  list-style-type: none;
  padding: 0;
}

.survey-resultItem {
  font-size: 18px;
  color: #bdc3c7;
  margin-bottom: 10px;
}

.survey-totalVotes {
  font-weight: bold;
  color: #f4f4f4;
  margin-top: 15px;
}

/* Mesajlar */
.survey-message, .survey-votedMessage {
  text-align: center;
  font-size: 16px;
  margin-top: 20px;
}

.survey-message {
  color: #e74c3c;
}

.survey-votedMessage {
  color: #f39c12;
  font-weight: bold;
}

.survey-poll p {
  color: #cccccc;
  font-size: 16px;
  margin: 10px 0 0 !important;
}

.yazar {
  color: #8e7bff;
  margin-bottom: 5px !important;
}